@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
.component-search-input > div {
  display: flex;
  margin: 0 15vw 5vw 15vw;
  padding-bottom: 0px;
  align-items: center;
}

.component-search-input input {
  border: 0px solid #D9D9D9;
  box-sizing: border-box;
  font-size: 15px ;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  width: 100%;
}

.component-search-box {
  display: flex;
  padding: 0px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.input-prefix{
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  color: #003399;
  padding: 9px 13px;
}

.img-search{
  height: 24px;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  image-orientation: revert;
}

::-webkit-input-placeholder{
  color: black;
}

::placeholder{
  color: black;
}

::-ms-input-placeholder{
  color: black;
}

Input:focus{
  border-color: #205685;
}

Input{
  outline: none;
}

@media screen and (max-width: 767px) {
  .component-search-input > div {
    margin: 21px auto 25px;
    padding-bottom: 0px;
  }
}

@media screen and (min-width:1100px) {
  .component-search-input > div {
    margin: 0 5vw 4vw 5vw;
    padding-bottom: 0px;
  }
}

.component-phone-data { /*cadre de tous les éléments*/
  position: flex;
}

.results-subtitle { /*texte information*/
  text-align: center;
  position: flex;
  color:#444444;
  font-size: 16px;
  margin-bottom: 24px;
}

/* Container de l'image / pièces / remise / total */
.phone-data-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  grid-gap: 16px;
  gap: 16px;
}

.component-phone-data-picture img{
  height: 400px;
  padding: 5px;
}

/* Boite affichage pièces et boite affichage prix */
.phone-data-info-box {
  align-items: center!important;
  display: flex;
  font-size: 17px;
  width: 450px;
}

.box-protection{
  width:100%;
}

/* Boite affichage protection et boite affichage prix */
.protection-data-info-box {
  align-items: center!important;
  display: flex;
  font-size: 17px;
  width: 450px;
}

/* Libellé d'une boite générique */
.info-box-label {
  padding: 10px 0px 10px 0px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  grid-gap:10px;
  gap:10px;
}
/* Libellé de la boite de protection */
.info-box-label-proctection{
  padding: 15px 0px 5px 0px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
}

.picto-piece{
  width: 28px
}

.picto-protection{
  width: 58px
}

.info-texte{
  text-align: center;
  margin-top: 10%;
  padding: 0% 2% 0 2%;
  width: 450px
}

/* Prix d'une boite générique */
.info-box-price {
  padding: 10px;
  margin-left: auto !important;
  display: flex;
  align-items: center;
  grid-gap: 13px;
  gap: 13px;
}

.info-box-price-protection{
  padding: 0px 10px;
  margin-left: auto !important;
  display: flex;
  grid-gap: 13px;
  grid-gap: 13px;
  gap: 13px;
  align-items: center;
  justify-content: flex-end;
}

.checkmark {
  height: 25px;
  width: 25px;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
}

/* Boite affichage pièces */
.item-info-box {
  margin-bottom: 10px;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  grid-gap: 5px;
  gap: 5px;
}

  .item-info-box-protection{
    margin-bottom: 30px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    grid-gap: 5px;
    grid-gap: 5px;
    gap: 5px;
  }

input[type=checkbox].item-checkbox {
  display: none;
}

.item-info-box:hover { /*bord de la boite passage souris*/
  border: 1px solid #003399 ;
}

.lab>input:checked+.item-info-box>*:last-child>.checkmark{
  height: 25px;
  border-radius: 5px;
  border: 0px solid #0019ff;
  content: url(https://static.wixstatic.com/media/787bb2_f32765ffee344781b2da8c61f5cb3315~mv2.jpg);
}

.lab>input:checked+.item-info-box { /*bord de la boite sélectionnée*/
  border: 0.0625rem solid #003399;
  background: #FFFFFF;
}

.item-info-box-protection:hover { /*bord de la boite passage souris*/
  border: 1px solid #003399 ;
}

.lab>input:checked+.item-info-box-protection>*:last-child>.checkmark{
  height: 25px;
  border-radius: 5px;
  border: 0px solid #0019ff;
  content: url(https://static.wixstatic.com/media/787bb2_f32765ffee344781b2da8c61f5cb3315~mv2.jpg);
}

.lab>input:checked+.item-info-box-protection { /*bord de la boite sélectionnée*/
  border: 0.0625rem solid #003399;
  background: #FFFFFF;
}

/* Container boites prix (remise et total) */
.component-phone-data-prices {
  margin-bottom: 10px;
}

/* Boite affichage prix (remise et total) */
.amount-info-box {
  margin-top: 10px;
  margin-bottom: -10px;
  padding: 10px;
  background: #F8F8F9;
  border-radius: 10px;
}

.text-protection{
  margin: 0px;
  padding-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .component-phone-data {
    width: 100%;
    margin: auto;
  }

  .component-phone-data-picture {
    display: none;
  }

  .phone-data-info-box {
    width: auto;
  }
}

/* Gestion du choix des couleurs de pièces */
input[type=radio] {
  display: none;
}

.colorOptions {
  display: flex;
}

.cardRadio+.cardColorBorder { /*cercle autour du bouton choix de couleur*/
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #D9D9D9;
  position: absolute;
  z-index: 10;
}

.cardColor { /*position du cercle de couleur dans le choix de couleur*/
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 3.5px;
  margin-left: 3.5px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}

.cardColorBorder:hover { /*souris sur le choix de la couleur*/
  cursor: pointer;
  border: 1px solid #686D74 ;
}

.cardRadio:checked+.cardColorBorder { /*sous bouton choix de couleur*/
  border: 1px solid #205685;
}

@media screen and (max-width:900px) {
  .phone-data-info-box {
    width: auto;
  }

  .protection-data-info-box {
    width: auto;
  }

  .info-texte{
    width: auto;
  }
}

.component-recap-page { /*cadre de tous les éléments de la page RecapPage.js */
    width: auto;
    margin: 0 24.5%;
}

.title-DataForm{
    text-align: center;
    color: #003399;
    box-sizing: border-box;
    font-size: 27px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin: 20px 0px 24px;
}

.sub-info{
  background: #f2eceB;
      padding: 25px 59px;
      border-radius: 13px;
      color: black;
      text-align: center;
      margin-top: 29px;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
}

.component-recap-page-info {
  font-size: 18px;
  margin: 26px 10px 16px;
  text-align: center;
  color: black;
  font-family: 'Roboto', sans-serif
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
}

.item-protection-recapPage{
    display: flex;
    align-items: center !important;
    font-size: 17px;
    width: 100%;
    margin-bottom: 30px;
    cursor: auto;
    border-radius: 5px;
    grid-gap: 5px;
    grid-gap: 5px;
    gap: 5px;
    background: #f2eceB;
}

.picto-protection-recapPage{
    padding: 10px 5px;
    width: 75px;
    display: flex;
}

.info-box-label-proctection-recapPage{
    padding: 0px;
      display: flex;
      font-size: 18px;
      flex-direction: row-reverse;
      text-align: center;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
}

.component-recap-page-title {
  text-align: center;
  color: #003399;
  box-sizing: border-box;
  font-size: calc(1rem + 1vw);
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}

.component-recap-bloc { /*Bloc gris comprenant l'image, le modèle et le prix */
  display: grid;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 25px 10px;
  background: #f2eceB;
  grid-gap:16px;
  gap:16px;
}

.component-recap-bloc-image {
  grid-column: 1;
  display: flex;

}

.component-recap-bloc-image img {
  width: 100%;
  object-fit: contain;
  padding: 5px;
}

.component-recap-bloc-model {
  grid-column: 2;
  align-self: center;
  max-width: 260px;
  min-width: 260px;
  text-align: start;
  color: black;
}

.component-recap-bloc-model-title {
  font-weight: bold;
  padding-bottom: 15px;
  font-size: 20px;
}

.component-recap-bloc-prices{
  display: flex;
  grid-column: 3;
  align-self: center;
  padding-left: 10px;
  /* border-left: 3px solid #D9D9D9; */
  margin: 32px 0px;
  justify-content: space-between;
}

.component-recap-bloc-totalAmount {
  font-weight: bold;
}

.component-recap-add-info {
  display: grid;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 15px 10px 8px 10px;
  border-bottom: 1px solid rgb(0 0 0);
  color: #000000;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .component-recap-page {
    width : auto;
    margin: 0 2%;
  }

  .sub-info{
    padding: 25px;
  }

  .component-recap-bloc {
    display: grid;
    grid-template-columns: auto auto;
    grid-column: 1 / 2;
  }

  .component-recap-bloc-image {
    display: none;
  }

  .component-recap-bloc-model {
    /* grid-column: 1; */
    padding: 10px;
    text-align: center;
  }

  .component-recap-bloc-prices {
    padding: 7px;
    font-size: 20px;
  }
}

.component-promo-code { /* Composant avec la case code de réduction + bouton validation */
  padding-bottom: 15px;
}

#validate-promo-code-button {
  min-width: 100%;
  padding: 0 10px;
  margin-top: 10px;
  width: 10%;
}

@media screen and (max-width: 767px) {
  #validate-promo-code-button {
    font-size: 15px;
  }
}

.end-info{
  padding: 22.3px 45px;
  font-size: 18px;
  text-align: center;
  border: 2px solid #003399;
  border-radius: 9px;
  margin-top: 28px;
  color: #003399;
  position: relative;
  transition: color .4s ease 0s;
  transition: var(--trans2,color .4s ease 0s);
}

.end-info:hover {
  background-color: #003399;
  color: white;
}

.box-info{
  margin: 0 24.5%;
}

.printDiscount{
  font-size: 20px;
}

.modal-text{
  color: black;
  font-weight: 100;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: sans-serif;
}

.modal-title{
  margin: 5px 0px;
}

.ant-btn-default {
    background-color: #003399;
    border-color: #003399;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    color: white;
}

.ant-btn-default:not(:disabled):hover {
    color: white;
    background: #003399;
    border-color: #003399;
}

.end-message {
  margin: 0 24.5%;
  font-size: 26px;
  color: #003399;
  text-align: center;
  font-weight: bolder;
}

.end-message.end-message-button{
  margin: 0px;
  display: grid;
  grid-gap: 12px;
  gap: 12px;
}

.end-message button {
  border: 0;
  outline: 0;
  width: 100%;
  background-color: white;
  height: 72px;
  padding: 0 15px;
  font-size: 18px;
  border-radius: 10px;
  color: #003399;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  border: 2px solid #003399;
}


.end-message_rdv button {
  border: 0;
  outline: 0;
  width: 100%;
  background-color:#003399;
  height: 72px;
  padding: 0 15px;
  font-size: 20px;
  border-radius: 10px;
  color: white;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  border: 2px solid white;
}

.end-message-button button {
  margin-top: 28px;
}

.end-message button:hover{
  color: white;
  background: #003399;
  border: 2px solid #003399;
  transition: color .4s ease 0s;
  transition: var(--trans1,color .4s ease 0s);
}

.switch-days-button {
  width: 4%;
  float:left;
}

.appointment-grid {
  width: 90%;
  float:left;
  border: 2px solid #205685;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.appointment-element {
  font-size: 22px;
  margin: 0 10%
}

.appointment-element-slot {
  font-size: 20px;
  border: solid;
  margin: 20px;
}

.checkbox-appointment-element-slot+.appointment-element-slot {
  border: 1px solid #D9D9D9;
  border-radius: 5px;
}

.checkbox-appointment-element-slot:hover+.appointment-element-slot { /*bord de la boite passage souris*/
  border: 1px solid #205685 ;
}

.checkbox-appointment-element-slot:checked+.appointment-element-slot { /*bord de la boite sélectionnée*/
  border: 2px solid #205685;
  background: #FFFFFF;
}

@media screen and (max-width: 767px) {
  .end-message {
    margin: 0px;
    font-size: 18px;
  }

  .box-info{
    margin: 0px;
  }

  .end-message-button button {
    font-size: 17px;
  }

  .end-info {
    font-size: 16px;
    padding: 25px 0px;
  }

  .appointment-grid {
    width: 73%;
    margin: 0px 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  .appointment-element {
    margin: 0px 8px;
    font-size: 16px;
  }

  #appointment-element-2{
    display: none;
  }

  .appointment-element-slot {
    padding-top: 12px;
    height: 30px;
    font-size: 14px;
    margin: 25px 0;
  }

  .switch-days-button {
    width: 10%;
    margin: 0 5px;
  }
}

body {
  width: 100%;
}

.text{
  margin: 0px;
}

.footer-main_wrapper{
  max-width: 1740px;
  margin: 0 auto;
  padding: 35px 5% 20px;

}

.container {
    background-color: #003399;
    text-align: center;
    height: auto;
    width: auto;

}

.footer-link {
  display: grid;
  grid-gap: 23px;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

.footer-main_footer h3 {
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:16px;
  color: white;
  text-align: start;
}

.footer-main_footer ul {
  display: table;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:30px;
  margin-top: -3px;
  padding: 0px
}

.footer-main_footer ul a {
  color:inherit;
  text-decoration:none;
  opacity:0.8;
  color: white;
}

.footer-main_footer ul a:hover {
  opacity:1;
}

.copyright {
    position: relative;
    color: white;
    margin-top: 44px;
    margin-bottom: 0;
    font-weight: bold;
    bottom: 35px;
}


.footer-item{
  width: 234px;
}

.footer-main{
  width: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-item .para {
  font-size: 21px;
  text-align: start;
  color: white;
  font-weight:bold;
  margin: 11px
}

.footer-item .highlighted{
  background-color: white;
  color: #003399;
  margin: 0;
  width: 165px;
}

@media screen and (min-width: 1024px) and (orientation: landscape) {

  .footer-link {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .footer-item .para{
    font-size: 23px;
  }

  .footer-item .highlighted {
    width: 186px;
  }

  .col-sm-4 .col-sm-3{
      display: grid;
      justify-items: center;
  }
}

@media screen and (max-width: 575px) {

  .footer-link {
    grid-template-columns: 1fr;
  }

  .footer-item .para {
    font-size: 17px;
  }

  .footer-item .highlighted{
    width: 133px;
  }

  .footer-main_wrapper {
    padding: 35px 10% 20px;
  }
}

@media screen and (max-width: 361px) {
  .footer-main{
    width: 50%
  }

  .footer-item .para {
    font-size: 21px;
  }

  .footer-item .highlighted{
    width: 165px;
  }
  .footer-main_wrapper{
    padding: 32px 34px 20px;
  }
}


@media screen and (min-width: 600px) {
  .footer-main_wrapper{
    padding: 35px 10% 20px;
  }
  .footer-link {
    justify-items: center;
}

}

.header-main {
    display: flex;
    flex-direction: column;
    height: 100px;
    background: white;
    box-shadow: 0 3px 10px rgba(0,0,0,.1);
    z-index: 1000;
}

.main-hearder .app__header {
    position: fixed;
    top: -1px;
    left: 0;
    width: 100%;
    height: 56px
}

.logo {
  width: 195px;
  margin-top: 6px;
}

.img-logo{
  width: 70%;
}

.header-main_wrapper{
  max-width: 1740px;
  padding: 0 20px;
  margin: 0 auto;
}

.header-main_header{
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 1;
  justify-content: space-between;
  box-sizing: border-box;
}

.header-main_nav{
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  font: normal normal normal 15px/1.4em roboto-bold,roboto,sans-serif;
  font: var(--fnt,var(--font_1));
  font-weight: 600;
  font-size: 15px;
  padding: 0 5px
}

.header_wrapper{
    max-width: 1740px;
    padding: 0 126px;
    margin: 0 auto;
}

.header-text{
  padding: 0 10px;
}

.button{
  color: #003399;
  margin: 0;
  position: relative;
  transition: color 0.4s ease 0s;
  transition: var(--trans2,color .4s ease 0s);
  white-space: nowrap;
  font-size: 16px
}

.box{
  border: 1px solid #003399;
  border-radius: 5px;
  margin: 0 5px
}

 .link_btn{
   align-items: center;
   box-sizing: border-box;
   border-radius: 2px;
   display: flex;
   justify-content: center;
   justify-content: var(--label-align);
   min-width: 100%;
   text-align: initial;
   width: 133px;
   height: 34px;
 }

  .link{
    color: #324158;
  }

 a{
   text-decoration: none;
 }

 .link:hover {
   color: #003399;
 }

 .link_btn:hover, .link_btn:hover span{
   background-color: #003399;
   color: white;

 }

:root{
--shd: none;
--fnt: normal normal normal 15px/1.4em roboto-bold,roboto,sans-serif;
--font_1: normal normal normal 12px/1.1em raleway,sans-serif;
--trans2: color 0.4s ease 0s;
--trans1: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
--label-align: center;
}

@media screen and (max-width: 1150px) {
  .header_wrapper {
  padding: 0 80px 0px 26px;
}

  .logo{
    width: 200px;
  }

  .header-main_nav{
    grid-column-gap: 22px;
    -webkit-column-gap: 22px;
            column-gap: 22px;
    font: normal normal normal 15px/1.4em roboto-bold,roboto,sans-serif;
    font: var(--fnt);
    font-weight: bold;
  }
}

@media screen and (max-width: 1025px) {

  .header_wrapper {
  padding: 0 80px 0px 26px;
}

  .logo{
    width: 202px;
  }

  .header-main_nav{
    grid-column-gap: 22px;
    -webkit-column-gap: 22px;
            column-gap: 22px;
  }

}

@media screen and (max-width: 850px) {
  .header-nav{
    display: none;
  }

  .header_wrapper {
    padding: 0 26px;
    grid-gap:15px;
    gap:15px;
}

  .logo{
    width: 150px;
  }
}

@media screen and (max-width: 370px) {
.header_wrapper{
  padding: 0 19px 0px 19px;
  box-sizing: border-box;
  }
}

@media screen and (max-width: 320px) {
  .logo{
    width: 120px;
  }

  .link_btn{
    width: 130px
  }

  .header_wrapper{
    padding: 0 19px 0px 19px;
    box-sizing: border-box;
    }

}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin: 0;
}

.main-page {
  margin-bottom: 30px;
  margin-top: 60px;
  margin-top: var(--desktop-header-height);
  padding-top: 20px;
  min-height: 70vh;
}

.page_wrapper{
  max-width: 1740px;
  padding: 0 20px;
  margin: 0 auto;
}

*, *::before, *::after {
    box-sizing: border-box;
}

:root {
    --desktop-wrapper-width: 1740px;
    --mobile-header-height: 10px;
    --desktop-header-height: 60px;
}

.results-title {
  width: 50%;
  margin: 40px auto;
  text-align: center;
  color: #003399;
  box-sizing: border-box;
  font-size: calc(1rem + 1vw);
  font-weight: 600;
}

.class-title {
  width: 58%;
  margin: 36px auto 11px;
  text-align: center;
  color: #003399;
  box-sizing: border-box;
  font-size: 23px;
  font-weight: 600;
}

.component-grid-list { /* Composant générique liste format grille */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px !important;
  width: 100%;
  text-align: center;
}

.component-grid-element { /* Composant générique élément de la liste format grille */
  border: 1px solid #EAE7EA;
  border-radius: 5px;
  aspect-ratio: 1/1;
  min-width: 100%;
  height: 100%;
  padding: var(--element-spacer-y) var(--element-spacer-x);
  --element-spacer-y: 1.25rem;
  --element-spacer-x: 1.25rem;
}

.component-grid-element-title {
  font-size: 18px;
  margin-top: -5%;
  font-family: 'Roboto', sans-serif;
}

#component-phones-title-element { /*Titre de la partie choix du modèle de téléphone*/
  margin-top: -17px;
}

.component-results-element { /* Composant générique "results" de la liste format grille */
  cursor: pointer;
}

.component-results-element:hover { /* Couleur au passage de la souris sur élément générique "results" */
  border-color: #003399;
}

.component-grid-element img { /* Format de l'image de l'élément générique */
  width: 100%;
  object-fit: contain;
}

#component-categories-results-element img { /* Format de l'image des catégories */
  height: 55%;
  min-height: 50px;
  margin: 8% 0px 5% 0px;
}

#component-brands-results-element img { /* Format de l'image des marques */
  height: 65%;
  width:100%;
  margin: 11% 0px 2% 0px;
}

#component-phones-results-element img { /* Format de l'image des modèles */
  height: 65%;
  width: 100%;
  margin: 11% 0px 2% 0px;
}

.component-skeleton-element-img {
  height: 130px;
}

.component-skeleton-element-title {
  height: 20px;
}

.component-grid-element-title { /* Titre des éléments génériques */
  position: relative;
  display: block;
  bottom: -10%;
}



.printDiscountQUALIREPAR{
 font-size: 18px;
 margin-top: -35px;
}

.printDiscountQUALIREPAR_Total{
  font-size: 32px;
  margin-top: -57px;
 }


.component-recap-bloc-totalAmount{
  font-size: 23px;
}


.detailBonusReprise {
  margin-top: 10px;
  color: black;
}




/* GESTION DES BOUTONS */

/* Bouton retour */
.return-button {
  left: 25em;
  margin: auto;
  margin-bottom: 0px;
  margin-top: 15px;
  padding: 0px;
  background-color: Transparent;
  font-family: 'Roboto', Bold, sans-serif;
  font-weight: 700;
  color: #003399;
  cursor: pointer;
  font-size: 1em;
  border: 0;
  transition: all 0.5s;
  border-radius: 10px;
  width: auto;
}

.return-button::before{
  content:"< ";
}

.return-button:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.return-button:after {
  opacity: 1;
  transition: all 0.5s;
}

/* Bouton de valdiation */
.validate-button{
  display: inline-block;
  box-shadow: none;
  -webkit-appearance: none;
          appearance: none;
  border: 0;
  outline: 0;
  background-color:#003399;
  height: 60px;
  width: 100%;
  padding: 0 15px;
  margin-top: 15px;
  font-size: 17px;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  box-sizing: border-box;
}

.validate-button:hover{
    -webkit-transform: scale(1.02, 1.02);
            transform: scale(1.02, 1.02);
}

.validate-button:active{
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
}

.steps{
  margin-top: 20px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon{
    border-color: #003399 !important;
    background-color: #003399 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #003399;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #003399;
}

@media screen and (min-width:1100px) {

  .page_wrapper{
    max-width: 1000px;
  }
}

@media screen and (max-width:900px) {
  .validate-button{
    width: 100%;
  }

  .class-title{
  font-size: calc(1rem + 1vw);
  }
}

@media screen and (min-width: 768px) {

  .component-grid-element-title {
    position: relative;
    display: block;
    bottom: -13%;
    font-size: 20px;
}

.return-button {
  margin-top: 40px;
  padding-left: 55px;
}
}

@media screen and (min-width: 768px) and (orientation: landscape) {

  .component-grid-element-title {
    position: relative;
    display: block;
    bottom: -13%;
    font-size: 20px;
}

.component-grid-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

}

/* AFFICHAGE MEDIA */
@media screen and (max-width: 760px) {

  .results-title {
    width: 100%;
    margin: 31px auto 24px;
  }

  #component-phones-title-element{ /*Titre de la partie choix du modèle de téléphone*/
    margin-top: -1%;
  }

  .component-grid-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center; /* left and right */
    justify-content: center; /* up and down */
  }

  .component-grid-element {
    width: 100px;
  }

  .component-grid-element img{
    margin-top: 10%;
    margin-bottom: 10%;
    height: 60px;
  }

  #component-phones-results-element img { /* Format de l'image des modèles */
  height: 65%;
  width: 100%;
  margin: 11% 0px 2% 0px;
  }

  .component-skeleton-element-img {
    height: 70px;
  }

  .component-skeleton-element-title {
    height: 15px;
  }

  .validate-button {
    font-size: 17px;
  }
}

@media screen and (max-width: 850px) and (orientation: landscape) {

  .results-title {
    width:100%;
    margin: 40px auto;
  }

  #component-phones-title-element{ /*Titre de la partie choix du modèle de téléphone*/
    margin-top: -1%;
  }

  .component-grid-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center; /* left and right */
    justify-content: center; /* up and down */
  }

  .component-grid-element {
    width: 100px;
  }

  .component-grid-element img{
    margin-top: 10%;
    margin-bottom: 10%;
    height: 60px;
  }

  #component-phones-results-element img { /* Format de l'image des modèles */
  height: 65%;
  width: 100%;
  margin: 11% 0px 2% 0px;
  }
}

@media screen and (max-width:575px) {
  .ant-steps {
  flex-direction: row !important;
  }

  .ant-steps-item-tail{
    display: inline-block;
  }

  .ant-steps .ant-steps-item:last-child {
    flex: none !important;
  }

  .ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-content{
  display: none;
  }

  .ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-icon {
    float: left;
    -webkit-margin-end: 16px;
            margin-inline-end: 16px;
    -webkit-margin-start: 13px;
            margin-inline-start: 13px;
  }
}

@media screen and (max-width:500px) {
  .component-grid-element-title {
    position: relative;
    display: block;
    bottom: -13%;
    font-size: 13px;
  }
}

@media screen and (max-width:700px) {
  .qualireparDesktop {
    display: none;
  }
}

@media screen and (min-width:701px) {
  .qualireparMobile {
    display: none;
  }
}

.qualireparDesktop{
  margin-top: 20px;
}

.qualireparMobile{
  margin-bottom: 10px;
}



.offreWEB{
  margin-bottom: 10px;
}


.wapper_rdv {
  background-color: white;
  text-align: center;
}

.dateSelectRDV {
  font-size: 25px;
  margin-bottom: 10px;
}

.button_rdv{
  margin-top: 2px;
  margin-bottom:  2px;
 
}


.dateValideRDV {
  font-size: 18px;
  margin-bottom: 10px;
}

.button_demande_rdv {
  background-color: #003399;
  color: #FFFFFF;
}
