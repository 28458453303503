@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin: 0;
}

.main-page {
  margin-bottom: 30px;
  margin-top: var(--desktop-header-height);
  padding-top: 20px;
  min-height: 70vh;
}

.page_wrapper{
  max-width: 1740px;
  padding: 0 20px;
  margin: 0 auto;
}

*, *::before, *::after {
    box-sizing: border-box;
}

:root {
    --desktop-wrapper-width: 1740px;
    --mobile-header-height: 10px;
    --desktop-header-height: 60px;
}

.results-title {
  width: 50%;
  margin: 40px auto;
  text-align: center;
  color: #003399;
  box-sizing: border-box;
  font-size: calc(1rem + 1vw);
  font-weight: 600;
}

.class-title {
  width: 58%;
  margin: 36px auto 11px;
  text-align: center;
  color: #003399;
  box-sizing: border-box;
  font-size: 23px;
  font-weight: 600;
}

.component-grid-list { /* Composant générique liste format grille */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px !important;
  width: 100%;
  text-align: center;
}

.component-grid-element { /* Composant générique élément de la liste format grille */
  border: 1px solid #EAE7EA;
  border-radius: 5px;
  aspect-ratio: 1/1;
  min-width: 100%;
  height: 100%;
  padding: var(--element-spacer-y) var(--element-spacer-x);
  --element-spacer-y: 1.25rem;
  --element-spacer-x: 1.25rem;
}

.component-grid-element-title {
  font-size: 18px;
  margin-top: -5%;
  font-family: 'Roboto', sans-serif;
}

#component-phones-title-element { /*Titre de la partie choix du modèle de téléphone*/
  margin-top: -17px;
}

.component-results-element { /* Composant générique "results" de la liste format grille */
  cursor: pointer;
}

.component-results-element:hover { /* Couleur au passage de la souris sur élément générique "results" */
  border-color: #003399;
}

.component-grid-element img { /* Format de l'image de l'élément générique */
  width: 100%;
  object-fit: contain;
}

#component-categories-results-element img { /* Format de l'image des catégories */
  height: 55%;
  min-height: 50px;
  margin: 8% 0px 5% 0px;
}

#component-brands-results-element img { /* Format de l'image des marques */
  height: 65%;
  width:100%;
  margin: 11% 0px 2% 0px;
}

#component-phones-results-element img { /* Format de l'image des modèles */
  height: 65%;
  width: 100%;
  margin: 11% 0px 2% 0px;
}

.component-skeleton-element-img {
  height: 130px;
}

.component-skeleton-element-title {
  height: 20px;
}

.component-grid-element-title { /* Titre des éléments génériques */
  position: relative;
  display: block;
  bottom: -10%;
}



.printDiscountQUALIREPAR{
 font-size: 18px;
 margin-top: -35px;
}

.printDiscountQUALIREPAR_Total{
  font-size: 32px;
  margin-top: -57px;
 }


.component-recap-bloc-totalAmount{
  font-size: 23px;
}


.detailBonusReprise {
  margin-top: 10px;
  color: black;
}




/* GESTION DES BOUTONS */

/* Bouton retour */
.return-button {
  left: 25em;
  margin: auto;
  margin-bottom: 0px;
  margin-top: 15px;
  padding: 0px;
  background-color: Transparent;
  font-family: 'Roboto', Bold, sans-serif;
  font-weight: 700;
  color: #003399;
  cursor: pointer;
  font-size: 1em;
  border: 0;
  transition: all 0.5s;
  border-radius: 10px;
  width: auto;
}

.return-button::before{
  content:"< ";
}

.return-button:hover {
  transform: scale(1.2);
}

.return-button:after {
  opacity: 1;
  transition: all 0.5s;
}

/* Bouton de valdiation */
.validate-button{
  display: inline-block;
  box-shadow: none;
  appearance: none;
  border: 0;
  outline: 0;
  background-color:#003399;
  height: 60px;
  width: 100%;
  padding: 0 15px;
  margin-top: 15px;
  font-size: 17px;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.validate-button:hover{
    transform: scale(1.02, 1.02);
}

.validate-button:active{
    transform: scale(1.05, 1.05);
}

.steps{
  margin-top: 20px;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon{
    border-color: #003399 !important;
    background-color: #003399 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #003399;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #003399;
}

@media screen and (min-width:1100px) {

  .page_wrapper{
    max-width: 1000px;
  }
}

@media screen and (max-width:900px) {
  .validate-button{
    width: 100%;
  }

  .class-title{
  font-size: calc(1rem + 1vw);
  }
}

@media screen and (min-width: 768px) {

  .component-grid-element-title {
    position: relative;
    display: block;
    bottom: -13%;
    font-size: 20px;
}

.return-button {
  margin-top: 40px;
  padding-left: 55px;
}
}

@media screen and (min-width: 768px) and (orientation: landscape) {

  .component-grid-element-title {
    position: relative;
    display: block;
    bottom: -13%;
    font-size: 20px;
}

.component-grid-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

}

/* AFFICHAGE MEDIA */
@media screen and (max-width: 760px) {

  .results-title {
    width: 100%;
    margin: 31px auto 24px;
  }

  #component-phones-title-element{ /*Titre de la partie choix du modèle de téléphone*/
    margin-top: -1%;
  }

  .component-grid-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center; /* left and right */
    justify-content: center; /* up and down */
  }

  .component-grid-element {
    width: 100px;
  }

  .component-grid-element img{
    margin-top: 10%;
    margin-bottom: 10%;
    height: 60px;
  }

  #component-phones-results-element img { /* Format de l'image des modèles */
  height: 65%;
  width: 100%;
  margin: 11% 0px 2% 0px;
  }

  .component-skeleton-element-img {
    height: 70px;
  }

  .component-skeleton-element-title {
    height: 15px;
  }

  .validate-button {
    font-size: 17px;
  }
}

@media screen and (max-width: 850px) and (orientation: landscape) {

  .results-title {
    width:100%;
    margin: 40px auto;
  }

  #component-phones-title-element{ /*Titre de la partie choix du modèle de téléphone*/
    margin-top: -1%;
  }

  .component-grid-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center; /* left and right */
    justify-content: center; /* up and down */
  }

  .component-grid-element {
    width: 100px;
  }

  .component-grid-element img{
    margin-top: 10%;
    margin-bottom: 10%;
    height: 60px;
  }

  #component-phones-results-element img { /* Format de l'image des modèles */
  height: 65%;
  width: 100%;
  margin: 11% 0px 2% 0px;
  }
}

@media screen and (max-width:575px) {
  .ant-steps {
  flex-direction: row !important;
  }

  .ant-steps-item-tail{
    display: inline-block;
  }

  .ant-steps .ant-steps-item:last-child {
    flex: none !important;
  }

  .ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-content{
  display: none;
  }

  .ant-steps.ant-steps-vertical >.ant-steps-item .ant-steps-item-icon {
    float: left;
    margin-inline-end: 16px;
    margin-inline-start: 13px;
  }
}

@media screen and (max-width:500px) {
  .component-grid-element-title {
    position: relative;
    display: block;
    bottom: -13%;
    font-size: 13px;
  }
}

@media screen and (max-width:700px) {
  .qualireparDesktop {
    display: none;
  }
}

@media screen and (min-width:701px) {
  .qualireparMobile {
    display: none;
  }
}

.qualireparDesktop{
  margin-top: 20px;
}

.qualireparMobile{
  margin-bottom: 10px;
}



.offreWEB{
  margin-bottom: 10px;
}


.wapper_rdv {
  background-color: white;
  text-align: center;
}

.dateSelectRDV {
  font-size: 25px;
  margin-bottom: 10px;
}

.button_rdv{
  margin-top: 2px;
  margin-bottom:  2px;
 
}


.dateValideRDV {
  font-size: 18px;
  margin-bottom: 10px;
}

.button_demande_rdv {
  background-color: #003399;
  color: #FFFFFF;
}